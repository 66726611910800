<template>
  <div class="comelnjw">
    <img src="../assets/jiewen_banner.png" alt="" class="comelnjw_img" />
    <!-- 关于文杰 -->
    <div class="comelnjw_content">
      <div class="comelnjw_content_box">
        <div class="comelnjw_content_box_left">
          <div class="comelnjw_content_box_left_icon">关于杰文</div>
          <div class="comelnjw_content_box_left_shuoming">
            <span>高校教育数字化创新应用的工匠</span>
            <div class="comelnjw_content_box_left_explain">
              河南杰文信息技术有限公司（简称：杰文信息）,位于中国·河南·郑州经济技术开发区·第九大街经南二路正商经开广场，是一家专注于软件产品研发、数字资源开发、VR虚拟现实、智慧实训室建设的教育信息化企业。公司的服务范围包括本科、高职、中职院校，以及企事业单位的职业培训信息化，是河南省高新技术企业、双软企业，并通过了ISO9001质量体系认证。
            </div>
          </div>
        </div>

        <div class="comelnjw_content_box_right" v-for="item in liebiaolist" :key="item.id">
          <video  controls="controls"
          :src="baseUrl+spList.pic"
          class="comelnjw_content_box_right_video"
            ></video>
          <div class="box">
            <div class="comelnjw_content_box_right_sj">
              <p>{{item.chenglinianxian}}</p>
              <div class="text">公司成立时间</div>
            </div>

            <div class="comelnjw_content_box_right_cooperate">
              <div class="cooperate_img">
                <div class="cooperate_img_i">{{ item.qiyerenshu }} <span>+</span></div>
                <div class="cooperate_img_b">软件著作研发</div>
              </div>
              <div class="cooperate_img img1">
                <div class="cooperate_img_i">{{ item.chenggonganli }} <span>+</span></div>
                <div class="cooperate_img_b">深度合作伙伴</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- 企业文化 -->
    <div class="box">
      <video class="corporateculture" :src="baseUrl+civilizationList.pic"
      loop autoplay muted
      >
      </video>
      <div class="corporateculture_box">
        <div class="corporateculture_wenhua">
          <p>企业文化</p>
          <div class="box-v"></div>
          <ul>
            <!-- <li>企业使命：</li> -->
            <li class="civilization_ani animate__delay-500ms">使命：用信息技术推进教育智慧化</li>
            <!-- <li>企业愿景：</li> -->
            <li class="civilization_ani">愿景：打造教育行业的知名品牌</li>
            <!-- <li>企业文化：</li> -->
            <li class="civilization_ani">文化：诚心、团结、创新、学习、共享</li>
            <!-- <li>企业理念：</li> -->
            <li class="civilization_ani">理念：专业专注追求卓越</li>
          </ul>
        </div>

        <div class="corporateculture_wenhua_right">
          <ul>
            <li>诚心</li>
            <li>团结</li>
            <li>创新</li>
            <li>学习</li>
          </ul>
        </div>

      </div>
    </div>

    <!-- 业务体系 -->
    <div class="system">
      <div class="system_img">业务体系</div>
      <div class="system_details">
        <div class="system_details_left">
          <div class="system_details_left_title">
            <!-- 为了保障为用户提供一站式的服务，公司体系健全，可以确保各个环节各个流程的有效实施。 -->
            十多年行业积淀，丰富的项目实施经验，为客户提供专业的一站式服务；
          </div>
          <ul>
            <li>
              河南杰文信息技术有限公司，依靠科技求发展，不断为客户提供满足高校智慧化数字产品，是我们一直的追求。在深耕行业应用和技术发展的基础上，将业务体系划分为杰文数字、杰文智能、杰文仿真、杰文软件、杰文学院五个核心板块。为客户提供一站式的解决方案与服务，实现共赢。
            </li>
          </ul>
        </div>
        <img src="../assets/tixi_tu.png" alt="" class="system_details_right system_ani" />
      </div>
    </div>
    <!-- 管理计划 -->
    <div class="plan-fa" :style="{backgroundPositionX: num*10+'%'}">
      <swiper ref="interSwiper" :options="bannerOption">
        <swiper-slide v-for="i in 5" :key="i" class="swiper-slide" > <!--:style="{backgroundPositionX: `${20*i}%`}"-->
          <div  v-if="yeardata.length" :ref="`plan${i}`" class="plan" >
            <div class="plan_box">
              <div class="year">{{ yeardata[i-1].niandu }}</div>
              <div class="plan_box_brand">打造全球品牌管理计划</div>
              <div class="plan_box_i">
                {{ yeardata[i-1].fubiaoti }}
              </div>
              <img src="../assets/jiantou_you.png" slot="button-next" alt="" class="plan_icon_you" @click='swiperNext(i)' />
              <img src="../assets/jiantou_zuo.png" slot="button-prev" alt="" class="plan_icon_zuo" @click='swiperPrev(i)' />
            </div>

          </div>
        </swiper-slide>
      </swiper>
      <div class="plan plan-bot" style="padding-top: 50px">
        <div class="plan_icon_xianzhou">
          <img src="../assets/zhou_biaoji_shang.png" alt="" class="plan_icon_bioaji_shang" ref="jiantoushang"  :style="{right: arrowList[num-1]}"/>
          <img src="../assets/zhou_biaoji_xia.png" alt="" class="plan_icon_bioaji_xia" ref="jiantouxia"  :style="{right: arrowList[num-1]}" />
          <img src="../assets/zuojuxing.png" alt="" class="img_zuojuxing">
          <img src="../assets/youjuxing.png" alt="" class="img_youjuxing">
        </div>
        <ul class="ul_year">
          <li v-for="(item, index) in yeardata" :key="index" @click="yearFn(index)">{{ item.niandu }}</li>
        </ul>
        <div class="plan_bottom">近十几<span>载的拼搏与奋斗</span></div>
        <p class="plan_bottom_p">NEARLY TEN YEARS OF HARD WORK AND STRUGGLE</p>
      </div>
    </div>


    <!-- 技术实力 -->
    <div class="TechnologyStrength">
      <div class="title">技术实力</div>
      <div class="title_box">
        “以技术为主号，以服务为基本，是我们永远的信念和不斯迫求的目标”
      </div>
      <img src="../assets/zhengshu/软著.png" alt="" class="TechnologyStrength_img technology_ani" />
      <ul style="width: 70%;margin: auto;">
        <li>
          <div class="tp">
            <p>PB级</p>
            <p style="font-size: 20px">数据处理能力</p>
          </div>
        </li>
        <li>
          <div class="tp">
            <p>3项</p>
            <p style="font-size: 20px">数据库技术</p>
          </div>
        </li>

      </ul>
      <ul>
        <li>
          <div class="tp">
            <p>30<span>+</span></p>
            <p style="font-size: 20px">企业各级荣誉</p>
          </div>
        </li>
        <li>
          <div class="tp">
            <p>56<span>+</span></p>
            <p style="font-size: 20px">软件著作权</p>
          </div>
        </li>

        <li>
          <div class="tp">
            <p>3000<span>+</span></p>
            <p style="font-size: 20px">企业知识资源</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="honour">
      <div class="honour_title">企业荣誉</div>
      <div class="honour_img">
        <img src="../assets/zhengshu/1.png" alt="" />
        <img src="../assets/zhengshu/2.png" alt="" />
        <img src="../assets/zhengshu/3.png" alt="" />
        <img src="../assets/zhengshu/4.png" alt="" />
        <img src="../assets/zhengshu/5.png" alt="" />
        <img src="../assets/zhengshu/6.png" alt="" />
        <img src="../assets/zhengshu/7.png" alt="" />
        <img src="../assets/zhengshu/8.png" alt="" />
      </div>
    </div>

    <contactus></contactus>
  </div>
</template>

<script>
import { comeinjwFn,civilizationFn,managePlanFn,TechnologyStrengthFn,comeinjwSpFn} from '@/api/comelnjw'
import contactus from "../components/ComeInJW/ContactUs.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

import { animate } from "../mixin/animate"

export default {
  name: "ComeInJW",
  mixins: [ animate ],
  components: {
    contactus,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      num: 0,
      //   关于杰文- 视频
      spList:{},
       // 杰文信息
      liebiaolist: [],
      // 企业文化
      civilizationList:{},
      //管理计划
      yeardata: [],
      baseUrl: process.env.VUE_APP_BASE_API,

      bannerOption: {
        loop: false,
        autoplay: {
          delay:500000,
        },
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      arrowList: ['1165px', '880px', '595px', '310px', '25px'],

      animateList: [{
        node: 'system_ani',
        class: ()=>{
          return 'animate__flipInY'
        }
      },{
          node: 'civilization_ani',
          class: (index)=>{
            return 'animate__lightSpeedInLeft'
          }
        },
      ]


    };
  },

  methods: {
    swiperNext(i) {
      console.log(i)
      if (i == 5) return
      this.num = i+1
      this.$refs.interSwiper.swiper.slideNext()
    },
    swiperPrev(i) {
      console.log(i)
      if (i == 1) return
      this.num = i-1
      this.$refs.interSwiper.swiper.slidePrev()
    },

    yearFn(index){
      this.num = index
      this.slithermethodFn()
    },

    comeinjwFnList() {
      // 杰文信息
      comeinjwFn().then(res => {
        this.liebiaolist = res.rows
      })
      //  关于杰文-宣传视频
      comeinjwSpFn({type: 12}).then(res=>{
      this.spList = res.rows[0]
      console.log(this.spList);
      })
      //  企业文化
      civilizationFn({
        type: 13
      }).then(res=>{
          this.civilizationList = res.rows[0]
      })
       // 走进杰文-管理计划
      managePlanFn().then(res=>{
        this.yeardata= res.rows
      })
    }
  },



  created() {
    this.comeinjwFnList()
  }

};
</script>

<style lang="scss" scoped>
.comelnjw_img {
  width: 100%;
}

.comelnjw_content {
  width: 100%;
  height: 940px;
  background: url("../assets/jiewen_bg_one.png");
  background-size: 100% 100%;

  .comelnjw_content_box {
    width: 1200px;
    height: 810px;
    margin: auto;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;

    .comelnjw_content_box_left {
      width: 529px;
      height: 100%;

      .comelnjw_content_box_left_icon {
        width: 421px;
        height: 102px;
        background: url("../assets/jiewen_biaoti.png");
        background-size: 100% 100%;
        font-size: 48px;
        font-weight: 500;
        color: #005fc6;
        line-height: 154px;
      }

      .comelnjw_content_box_left_shuoming {
        width: 529px;
        height: 608px;
        background: #ffffff;
        border-radius: 4px;
        margin-top: 100px;
        box-shadow: 0px 3px 20px 0px rgb(19 19 30 / 14%);

        span {
          font-size: 24px;
          font-weight: 400;
          color: #333333;
          padding-top: 69px;
          padding-bottom: 40px;
          text-align: center;
          display: block;
        }

        .comelnjw_content_box_left_explain {
          width: 437px;
          height: 354px;
          font-size: 18px;
          font-weight: 400;
          color: #999999;
          line-height: 48px;
          text-align: justify;
          margin: auto;
        }
      }
    }

    .comelnjw_content_box_right {
      width: 585px;
      height: 100%;

      .comelnjw_content_box_right_video {
        width: 100%;
        height: 376px;
      }
    }
  }
}

.comelnjw_content_box_right_sj {
  p {
    font-size: 90px;
    font-weight: 400;
    color: #f49c00;
  }

  .text {
    font-size: 20px;
    font-weight: 400;
    color: #666666;
    // margin-top: 40px;
  }
}

.cooperate_img {
  width: 236px;
  height: 160px;
  background: url("../assets/bg_ruanjian.png");
  background-size: 100% 100%;
  margin-bottom: 40px;

  .cooperate_img_i {
    font-size: 36px;
    font-weight: 800;
    color: #ffffff;
    padding-top: 70px;
    padding-left: 118px;

    span {
      font-size: 18px;
      font-weight: 800;
    }
  }

  .cooperate_img_b {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    padding-left: 118px;
  }
}

.img1 {
  background: url("../assets/bg_hezuo.png");
  margin-bottom: 0px;
}

.box {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.corporateculture_box {
  width: 1200px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.box {
  position: relative;
}

.corporateculture {
  width: 100%;
  height: 640px;
}


.corporateculture_wenhua {
  width: 540px;
  height: 626px;
  background: url("../assets/icon.png");
  background-size: 100% 100%;
  overflow: hidden;
  position: absolute;
  top: -54px;
  left: 0;

  p {
    font-size: 48px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 54px;
    margin-left: 96px;
    margin-bottom: 34px;
  }

  .box-v {
    width: 447px;
    height: 3px;
    background: #f05b20;
    margin: auto;
  }

  ul {
    width: 430px;
    height: 387px;
    margin-top: 38px;
    margin-left: 50px;

    li {
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      line-height: 70px;
    }
  }
}

.corporateculture_wenhua_right {
  position: absolute;
  bottom: 30px;
  right: 0;

  ul {
    width: 336px;

    li {
      font-size: 48px;
      font-weight: bold;
      color: #ffffff;
      float: left;
      margin: 30px 36px;
    }
  }
}

.system {
  .system_img {
    width: 1222px;
    height: 102px;
    background: url("../assets/tixi_wenzi.png");
    background-size: 100% 100%;
    font-size: 48px;
    font-weight: 500;
    color: #005fc6;
    line-height: 154px;
    margin: auto;
    margin-top: 88px;
  }

  .system_details {
    width: 1450px;
    padding-left: 120px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 28px;
    padding-bottom: 600px;
    position: relative;

    .system_details_left {
      position: absolute;
      left: 100px;
      z-index: 10;

      .system_details_left_title {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        margin-bottom: 40px;
      }

      ul {
        width: 563px;
        height: 427px;

        li {
          width: 500px;
          height: 360px;
          font-size: 24px;
          font-weight: 400;
          color: #333333;
          line-height: 60px;
          text-align: justify;
        }
      }
    }

    .system_details_right {
      position: absolute;
      right: 0;
      z-index: 1;
    }
  }
}
.plan-fa {
  //width: 500vw;
  overflow:hidden;
  //display:flex;

  overflow: hidden;
  background: url("../assets/dashiji_bg.jpg");
  background-size: auto;
  background-position-x: 10%;
  background-position-y: bottom;
  transition: linear 0.5s;
}

.swiper-slide {
  //background: url("../assets/shiji_bg.png");
  //background: url("../assets/shiji_bg_long.png");
  //background-size: 50% 100%;
}
.plan-top {
  width: 100vw;
  height: 422px;
  overflow: hidden;
  //background: url("../assets/shiji_bg.png");
}
.plan-bot {
  width: 100vw;
  height: 422px;
}
.plan {

  .plan_box {
    width: 1200px;
    height: 400px;
    margin: auto;
    position: relative;

    .year {
      font-size: 300px;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.07;
      text-align: center;
      position: absolute;
      left: 296px;
    }

    .plan_box_brand {
      font-size: 48px;
      font-weight: 400;
      color: #ffffff;
      position: absolute;
      z-index: 10;
      top: 174px;
      left: 350px;
    }

    .plan_box_i {
      width: 800px;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      position: absolute;
      z-index: 10;
      left: 0;
      right: 0;
      bottom: 90px;
      margin: auto;
      text-align: center;
    }

    .plan_icon_you {
      position: absolute;
      top: 220px;
      right: 0;
      cursor: pointer;
    }

    .plan_icon_zuo {
      position: absolute;
      top: 220px;
      left: 0;
      cursor: pointer;
    }
  }

  .plan_icon_xianzhou {
    width: 1200px;
    height: 11px;
    margin: auto;
    background: url("../assets/xianzhou.png");
    background-size: 100% 100%;
    position: relative;

    .plan_icon_bioaji_shang {
      position: absolute;
      right: 1165px;
      top: 18px;
      transition: linear 0.5s;
    }

    .plan_icon_bioaji_xia {
      position: absolute;
      right: 1165px;
      bottom: 16px;
      transition: linear 0.5s;
    }

    .img_youjuxing {
      position: absolute;
      right: -348px;
      bottom: 0;
    }

    .img_zuojuxing {
      position: absolute;
      left: -340px;
      bottom: 0;
    }
  }

  .ul_year {
    width: 1200px;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;

    li {
      color: #ffffff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .plan_bottom {
    font-size: 48px;
    font-weight: 400;
    color: #efbc48;
    text-align: center;
    margin-top: 100px;

    span {
      color: #ffffff;
    }
  }

  .plan_bottom_p {
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 80px;
  }
}

.TechnologyStrength {
  width: 1200px;
  margin: 80px auto;

  .title {
    font-size: 36px;
    font-weight: 500;
    color: #333333;
    text-indent: 50px;
  }

  .title_box {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    margin-top: 25px;
    margin-bottom: 20px;
    text-indent: 40px;
  }

  ul {
    display: flex;
    margin-left: 40px;
    justify-content: space-evenly;
    flex-wrap: wrap;

    li {
      width: 28%;
      height: 76px;
      margin-top: 70px;

      .tp {
        width: 100%;
        height: 100%;
        background: url("../assets/zhuanli_tuer.png");
        background-size: 100% 100%;

        p {
          color: #bd9448;
          font-weight: 400;
          text-align: center;
          font-size: 36px;
          line-height: 38px;
          position: relative;

          span {
            font-size: 24px;
            font-weight: 400;
            position: absolute;
            top: -10px;
          }
        }
      }
    }
  }
}

.honour {
  width: 100%;
  height: 820px;
  background: url("../assets/rongyu_bg.png");
  background-size: 100% 100%;
  overflow: hidden;

  .honour_title {
    font-size: 68px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    margin-top: 40px;
  }

  .honour_img {
    width: 1200px;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 120px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    img {
      margin-top: 60px;
    }
    img:hover {
      transform:scale(1.1,1.1);
      transition:Linear 0.3s
    }
  }

  .giveservice {
    width: 100%;
    height: 164px;
    background: url("../assets/rongyu_bg.png");
    background-size: 100% 100%;
  }
}

.TechnologyStrength_img {
  margin-left: 30px;
}</style>

<template>
  <div>
    <div class="giveservice">
      <div class="giveservice_box">
        <div class="giveservice_text">联系我们，为您提供一站式服务</div>
        <div class="leave_advertise" @click="handleAboutUs(0)">
          <img src="../../assets/icon_liuyan.png" alt="" class="leave_advertise_img" />
          <span>在线留言</span>
        </div>
        <div class="leave_advertise" @click="handleAboutUs(1)">
          <img src="../../assets/icon_zhaopin.png" alt="" class="leave_advertise_img" />
          <span>人才招聘</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleAboutUs(id) {
      this.$router.push({path:'/about-us',query:{id:id}})
    },
  }
}
</script>

<style lang="scss" scoped>
.giveservice {
  width: 100%;
  height: 164px;
  background: url("../../assets/liuyan_bg.png");
  background-size: 100% 100%;
  overflow: hidden;

  .giveservice_box {
    width: 1100px;
    justify-content: space-between;
    align-items: center;
    margin: 50px auto;
    display: flex;

    .giveservice_text {
      font-size: 36px;
      font-weight: 400;
      color: #ffffff;
    }

    .leave_advertise {
      width: 230px;
      height: 60px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;

      img {
        width: 32px;
        height: 32px;
      }

      span {
        font-size: 24px;
        font-weight: 500;
        color: #005fc6;
      }
    }
  }
}
</style>
import request from '@/utils/request'

//  走进杰文-关于杰文
export function comeinjwFn() {
    return request({
      url: '/business/info/list',
      method: 'get',
      
    })
  }
  //  走进杰文-关于杰文宣传视频
  export function comeinjwSpFn(params) {
    return request({
      url: '/business/pic/list',
      method: 'get',
      params
    })
  }

  // 走进杰文-企业文化
  export function civilizationFn(params) {
    return request({
      url: '/business/pic/list',
      method: 'get',
      params
    })
  }

    // 走进杰文-管理计划
    export function managePlanFn() {
      return request({
        url: '/business/shijianzhou/list',
        method: 'get',
      })
    }

